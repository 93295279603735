import { useRef, useState, React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEventToSave, changeUserData, deleteAllChangedYears} from "../utils/rdxFunction";
import XmlHandler from "../utils/xmlHandler";

export default function Editor(){

    const localSave = async ()=>{


        let currentDataToSave = {...dataToSave}

        if(changesMade){

            const xmlIds = []

            booksDatas[1].forEach(translatedBook=>{
                xmlIds.push(translatedBook[allEvents[eventIndex]] ? translatedBook[allEvents[eventIndex]].xmlId : undefined)
            })
    
            const currentIndexData = {
                notesInputValues,
                translationsInputValues,
                xmlIds
            }

            dispatch(addEventToSave({key:allEvents[eventIndex], value:currentIndexData}))
            currentDataToSave = {...currentDataToSave, 
                [allEvents[eventIndex]]:currentIndexData
            }
        }

        setChangesMade(false)
        return currentDataToSave
    }

    const saveKey = (e)=>{
        if((e.ctrlKey || e.metaKey) && e.key=="s"){
            e.preventDefault()
            save()
        }
    }

    const save= async ()=>{
        const currentDataToSave = await localSave()
        fetch("/save", {
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({dataToSave:currentDataToSave})
        })
        .then(data=>data.text())
        .then(data=>{
            if(data.includes("DONE")){
                savedLabelRef.current.classList.remove("savedLabelWithAnim")
                savedLabelRef.current.offsetWidth;
                savedLabelRef.current.classList.add("savedLabelWithAnim")
                dispatch(deleteAllChangedYears())
                getAllChanges()
            }
            else{
                alert(data)
            }
        })
        setChangesMade(false)
    }

    const getAllChanges = async ()=>{
        await(
            await fetch("get-all-changes")
        ).text()
        .then(data=>{
            if(data.includes("{")){
                const serverData = JSON.parse(data)
                const newData = {}
                serverData.forEach(dataItem=>{
                    newData[dataItem.event] = dataItem.texts
                })
                setServerChangesMade(newData)
            }
        })
    }

    const saveEventIndexTimer = useRef(null)

    const saveNewEventIndex = async (newIndex)=>{
        if(saveEventIndexTimer.current){
            clearTimeout(saveEventIndexTimer.current)
        }
        saveEventIndexTimer.current = setTimeout(async ()=>{
            await fetch("/change-current-event-index",{
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    currentEventIndex:newIndex
                })
            })
        }, 500)        
    }

    const setNewEventIndex = async (value)=>{
        await localSave()
        const newIndex = parseInt(value)
        setEventIndex(newIndex)
        saveNewEventIndex(newIndex)
    }

    const previousEvent= async ()=>{
        await localSave()
        if(eventIndex > 0){
            const newIndex = eventIndex - 1
            setEventIndex(newIndex)
            saveNewEventIndex(newIndex)
        }
    }

    const nextEvent = async ()=>{
        await localSave()
        if(eventIndex < allEvents.length - 1){
            const newIndex = eventIndex + 1
            setEventIndex(newIndex)
            saveNewEventIndex(newIndex)
        }
    }

    const changeInputValues = (type, e, index)=>{
        const updateFunction = type === "notes" ? setNotesInputValues : setTranslationsInputValues
        const values = type === "notes" ? [...notesInputValues] : [...translationsInputValues]

        values[index] = e.target.value

        updateFunction(values)
        setChangesMade(true)
    }


    const textsinfos = [
        "MS A : Winchester Chronicle (Cambridge, Corpus Christi College, MS 173)",
        "MS B : Abingdon Chronicle I (Londres, British Library, Cotton Tiberius A.vi)",
        "MS C : Abingdon Chronicle II (Londres, British Library, Cotton Tiberius B.i)",
        "MS D : Worcester Chronicle (Londres, British Library, Cotton Tiberius B.iv)",
        "MS E : Peterborough Chronicle (Oxford, Bodleian, Laud, misc 636)"
    ]

    const userData = useSelector(state=>state.userData.userData)
    const isDarkMode = useSelector(state=>state.isDarkMode)
    const dataToSave = useSelector(state=>state.dataToSave)
    const dispatch = useDispatch()

    const numberOfEditors = 5; 

    const savedLabelRef = new useRef(null)

    const buttonRef = new useRef(null)

    const [display, setDisplay] = useState(false)
    const [allEvents, setAllEvents] = useState([])
    const [eventIndex, setEventIndex] = useState(userData.currentEventIndex ? userData.currentEventIndex : 0)
    const [changesMade, setChangesMade] = useState(false)

    const [serverChangesMade, setServerChangesMade] = useState({})

    const [booksDatas, setBooksDatas] = useState([])
    const [generalEventsData, setGeneralEventsData] = useState({})

    const [notesInputValues, setNotesInputValues] = useState(Array(numberOfEditors).fill(""))
    const [translationsInputValues, setTranslationsInputValues] = useState(Array(numberOfEditors).fill(""))


    // Load the XML data each time page is reloaded
    useEffect( ()=>{

        const updateData = async ()=>{

            await getAllChanges()

            const xml = new XmlHandler(userData.xmlList)
            await xml.parseAllXMLTexts()
            const allBooksList = await xml.getBooksLists()
            setBooksDatas(allBooksList)
            const events = await xml.getAllEvents()
            setAllEvents(events)
            const generalEvents = await xml.getParsedEventsList()
            setGeneralEventsData(generalEvents)
            const newUserData = {...userData}
            dispatch(changeUserData(newUserData))
            setTimeout(()=>{
                setDisplay(true)
            },1000) 
        }
        updateData()
        const interval = setInterval(()=>{
            if(buttonRef.current){
                buttonRef.current.click()
            }
        }, 30000) 


        return ()=>{
            clearInterval(interval)
        } 
    }, [])


    useEffect(()=>{
        if(booksDatas && booksDatas.length){

            const modifiedValues = dataToSave[allEvents[eventIndex]] ? dataToSave[allEvents[eventIndex]] : 
            serverChangesMade[allEvents[eventIndex]] ? serverChangesMade[allEvents[eventIndex]] : undefined

            setTranslationsInputValues(translationsInputValues.map((value, index)=>{
                return modifiedValues ? modifiedValues.translationsInputValues[index] : 
                booksDatas[1][index][allEvents[eventIndex]] ? booksDatas[1][index][allEvents[eventIndex]].content : ""
            }))
    
            setNotesInputValues(notesInputValues.map((value, index)=>{
                return modifiedValues ? modifiedValues.notesInputValues[index] : 
                booksDatas[1][index][allEvents[eventIndex]] ? booksDatas[1][index][allEvents[eventIndex]].note : ""
            }))
        }

    }, [eventIndex, booksDatas])



    if(display && booksDatas){
        
        return(
            <div className={`editorContainer ${isDarkMode ? "dark":"light"}`}>
                <p className="savedLabel" ref={savedLabelRef}>Enregistré</p>
                <div className="editor">
                    <div  className={`textsInformations ${isDarkMode ? "dark":"light"}`}>
                        {
                            [...Array(numberOfEditors)].map((value, index)=>{
                                return(
                                    <p className="textsInformationsItem" key={index}>
                                        {textsinfos[index]} <br/><br/>
                                        <span className="bold">{booksDatas[0][index][allEvents[eventIndex]] ? `Sub anno : ${booksDatas[0][index][allEvents[eventIndex]].customDate}` : "N/A"} </span>
                                    </p>
                                )
                            })
                        }
                    </div>
                    <div  className={`editorOriginal ${isDarkMode ? "dark":"light"}`}>
                        {
                            [...Array(numberOfEditors)].map((value, index)=>{                                
                                return(
                                    <textarea 
                                        key={index} 
                                        className={"editorItem" + index} 
                                        contentEditable={false}
                                        spellCheck={false}
                                        readOnly={true}
                                        disabled={booksDatas[1][index][allEvents[eventIndex]] ? false: true}
                                        style={{fontSize:userData.textSize+"px"}}
                                        value={booksDatas[0][index][allEvents[eventIndex]] ? booksDatas[0][index][allEvents[eventIndex]].content : ""}
                                    >
                                    </textarea>
                                )
                            }) 
                        }
                    </div>
                    <div  className={`editorEdit ${isDarkMode ? "dark":"light"}`}>
                        {
                            [...Array(numberOfEditors)].map((value, index)=>{
                                return(
                                    <textarea key={index} 
                                        className="editorItem" 
                                        spellCheck={false}
                                        onKeyDown={saveKey}
                                        style={{fontSize:userData.textSize+"px"}}
                                        //defaultValue={defaultInputValues[index]}
                                        value={translationsInputValues[index]}
                                        onChange={(e)=>{changeInputValues("translations", e, index)}}
                                        disabled={booksDatas[1][index][allEvents[eventIndex]] ? false: true}
                                    >
                                    </textarea>
                                )
                            }) 
                        }
                    </div>

                    <div  className={`editorEdit ${isDarkMode ? "dark":"light"}`}>
                        {
                            [...Array(numberOfEditors)].map((value, index)=>{
                                return(
                                    <textarea key={index} 
                                        className="editorItem" 
                                        spellCheck={false}
                                        onKeyDown={saveKey}
                                        style={{fontSize:userData.textSize+"px"}}
                                        //defaultValue={defaultInputValues[index]}
                                        value={notesInputValues[index]}
                                        onChange={(e)=>{changeInputValues("notes", e, index)}}
                                        disabled={booksDatas[1][index][allEvents[eventIndex]] ? false: true}
                                    >
                                    </textarea>
                                )
                            }) 
                        }
                    </div>


                </div>
                <div className="textMenu">
                    <div  className={`textMenuDateHandler ${isDarkMode ? "dark":"light"}`}>
                        <div className="date">
                            <img src={require("../images/calendar.png")} alt="Date"/>

                            

                            <select type="text" value={eventIndex} onChange={(e)=>{setNewEventIndex(e.target.value)}}>
                                {
                                    allEvents.map((event, index)=>{
                                        return <option key={index} value={index}>{event}</option>
                                    })
                                }
                            </select>
                        </div>
                        <button onClick={previousEvent}>Précédent <img src={require("../images/previous.png")} alt="<-"/></button>
                        <button onClick={nextEvent}>Suivant <img src={require("../images/next.png")} alt="->"/></button>
                    </div>

                    <div  className={`textMenuSaveHandler ${isDarkMode ? "dark":"light"}`}>
                        <div className="genericInfo" title={generalEventsData[allEvents[eventIndex]] ? generalEventsData[allEvents[eventIndex]].note : ""}>
                            {generalEventsData[allEvents[eventIndex]] ? generalEventsData[allEvents[eventIndex]].note : ""}
                        </div>
                        <div className="genericInfo" title={generalEventsData[allEvents[eventIndex]] ? generalEventsData[allEvents[eventIndex]].label : ""}>
                            {generalEventsData[allEvents[eventIndex]] ? generalEventsData[allEvents[eventIndex]].label : ""}
                        </div>
                        <div className="genericInfo">
                            {generalEventsData[allEvents[eventIndex]] ? generalEventsData[allEvents[eventIndex]].date : ""}
                        </div>
                        <button onClick={save} ref={buttonRef}>
                            Enregistrer
                            <img src={require("../images/save.png")} alt=""/>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    else{
        return(
            <div className={`loadingPage ${isDarkMode ? "dark":"light"}`}>
                <p>Chargement des données</p>
                <div className="spinner"></div>
            </div>
        )
    }
}