import React, { useState } from "react"
import { useSelector } from "react-redux"

export default function FirstConnection({setModalWindow, setModalVisible, Windows}){

    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [title, setTitle] = useState("Veuillez choisir un nouveau mot de passe")

    const isDarkMode = useSelector(state=>state.isDarkMode)

    const changePassword = ()=>{
        fetch("/firstconnection", {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
                newPassword,
                confirmNewPassword
            })
        })
        .then(data=>data.text())
        .then(data=>{
            if(data.includes("DONE")){
                setTitle("Mot de passe modifié !")
                setTimeout(()=>{
                    setModalVisible(false)
                    setModalWindow(Windows.SETTINGS)
                },1000)
            }
            else{
                alert(data)
            }
        })

    }

    return(
        <div className={`firstConnection ${isDarkMode ? "dark":"light"}`}>
            <h1>Première connexion</h1>
            <h3>{title}</h3>
            <input type="password" value={newPassword} onChange={(e)=>{setNewPassword(e.target.value)}} placeholder="Nouveau mot de passe" />
            <input type="password" value={confirmNewPassword} onChange={(e)=>{setConfirmNewPassword(e.target.value)}} placeholder="Confirmation du nouveau mot de passe" />
            <button className="valider" onClick={changePassword}>Valider</button>
        </div>
    )
}