
export default class XmlHandler{
    xmlFile = ""
    data

    originalBooksList = []
    translationBooksList = []
    generalEventsList = {}

    generalXMLData = ""
    originalXMLData = ""
    translationXMLData = ""

    parser = new DOMParser()
    texts = []
    allEvents = []
    emptyXmlData = []


    constructor(xmlFile){ // xmlFile is a list of three files
        if(xmlFile != null){
            this.xmlFile = xmlFile
        }
    }

    parseXMLString = async (stringXML)=>{
        return this.parser.parseFromString(stringXML, "text/html")
    }


    parseAllXMLTexts = async()=>{
        this.generalXMLData = await this.parseXMLString(this.xmlFile[0])
        this.originalXMLData = await this.parseXMLString(this.xmlFile[1])
        this.translationXMLData = await this.parseXMLString(this.xmlFile[2])
    }

    getBooksLists = async ()=>{

        [this.originalXMLData, this.translationXMLData].forEach((parsedXML, index)=>{

            const currentBooksList = index === 0 ? this.originalBooksList : this.translationBooksList


            const books = Array.from(parsedXML.querySelectorAll('[type="livre"]'))

            books.forEach((book, listindex)=>{

                const currentBookObject = {}

                const listEvents = Array.from(book.querySelectorAll("listEvent"))

                listEvents.forEach(listEvent=>{

                    const date = listEvent.firstElementChild;

                    const customDate = date.getAttribute("when-custom")
                    const customDateContent = date.textContent
                    const events = Array.from(listEvent.children).filter(child=>{return child.tagName === "EVENT"})

                    
                    events.forEach(event=>{

                        const refKey = event.getAttribute("ref")
                        const xmlId = event.getAttribute("xml:id")


                        if(!this.allEvents.includes(refKey)){
                            this.allEvents.push(refKey)
                        }


                        const eventNote = event.firstElementChild.firstElementChild ? 
                        event.firstElementChild.firstElementChild.textContent : ""

                        currentBookObject[refKey] = ({
                            customDate,
                            customDateContent,
                            content:event.firstElementChild.textContent.replace("Note", "").replace("note", "").replace("de travail", ""),
                            note:eventNote,
                            xmlId
                        })
                    })
                    
                    
                })

                currentBooksList.push(currentBookObject)

            })            
        })

        return [this.originalBooksList, this.translationBooksList]
    }

    getParsedEventsList = async ()=>{
        // This function is about eventsList.xml file
        const eventsList = this.generalXMLData.querySelectorAll("event")
        eventsList.forEach((event, index)=>{
            const newEventObj = {
                date:event.getAttribute("when"),
            }

            Array.from(event.children).forEach((child)=>{
                if(child.getAttribute("type") === "label"){
                    newEventObj.label = child.textContent
                }
                else if(child.getAttribute("type") === "travail"){
                    newEventObj.note = child.textContent
                }
            })

            this.generalEventsList[event.getAttribute("xml:id")] = newEventObj
        })

        return this.generalEventsList
    }

    getAllEvents = async ()=>{
        return this.allEvents.sort((a,b)=>{return parseFloat(a) - parseFloat(b)})
    }

    

}