import {RdxConsts} from "./rdxConsts"

export const changeLoggedIn = (payload)=>{
    return {
        type:RdxConsts.changeLoggedIn,
        payload
    }
}
export const changeDarkMode = (payload)=>{
    return{
        type:RdxConsts.changeDarkMode, 
        payload
    }
}

export const changeUserData = (payload)=>{
    return{
        type:RdxConsts.changeUserData,
        payload
    }
}

export const addEventToSave = (payload)=>{
    return{
        type:RdxConsts.addEventToSave,
        payload
    }
}

export const deleteAllChangedYears = ()=>{
    return{
        type:RdxConsts.deleteAllChangedYears
    }
}

export const changeXmlHeader = (payload)=>{
    return{
        type:RdxConsts.changeXmlHeader,
        payload
    }
}