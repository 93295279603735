import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function AddUser({Windows, setModalWindow, type, logout}){

    const initialTitle = type == Windows.ADDUSER ? "Veuillez remplir les informations suivantes" : "Modifier mes informations"

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [description, setDescription] = useState("")
    const [title, setTitle] = useState(initialTitle)

    const isDarkMode = useSelector(state=>state.isDarkMode)

    const userAdd = ()=>{
        fetch("/adduser", {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
                name, 
                email,
                description
            })
        })
        .then(data=>data.text())
        .then(data=>{
            if(data.includes("Utilisateur ajouté")){
                setTitle(data)
                setTimeout(()=>{
                    setTitle(initialTitle)
                    setName("")
                    setEmail("")
                    setDescription("")
                },1500)
            }
            else{
                alert(data)
            }
        })
    }

    const modifyInfos = ()=>{
        fetch("/modifyUserInformations", {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
                name,
                description
            })
        })
        .then(data=>data.text())
        .then(data=>{
            setTitle(data)
            if(data.includes("reconnecter")){
                setTimeout(()=>{
                    logout()
                }, 1000)
            }
        })
    }

    const goBack = ()=>{
        setModalWindow(Windows.SETTINGS)
        setTitle(initialTitle)
        setName("")
        setEmail("")
        setDescription("")
    }

    return(
        <div className={`firstConnection ${isDarkMode ? "dark":"light"}`}>
            <h1>{type == Windows.ADDUSER ? "Ajouter un nouvel utilisateur":"Modifier mes informations"}</h1>
            <h3>{title}</h3>
            <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Nom" />
            {type==Windows.ADDUSER ? <input type="text" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="E-mail" />:""}
            <input type="text" value={description} onChange={(e)=>{setDescription(e.target.value)}} placeholder="Description" />
            <button className="valider" onClick={type==Windows.ADDUSER ? userAdd : modifyInfos}>Valider</button>
            <button className="goBack" onClick={goBack}>{"<"} Retour</button>
        </div>
    )
}