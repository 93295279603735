import { useSelector } from "react-redux"


export default function AllUsers(){

    const allUsers = useSelector(state=>state.userData.userData.allUsers)
    const isDarkMode = useSelector(state=>state.isDarkMode)


    return(
        <div className={`approvals ${isDarkMode ? "dark":"light"}`}>
            <h1>Liste des utilisateurs</h1>
            {
                allUsers.slice().map(item=>{
                    return(
                        <div className="approvalItem">
                            <p>{item.name}</p>
                            <p>{item.description}</p>
                            <p>{item.email}</p>
                        </div>

                    )
                })
            }
        </div>
    )
}