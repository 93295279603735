import React, {useState} from "react";
import { useSelector } from "react-redux";

export default function Notes(){

    const ctrls = (e)=>{
        if((e.ctrlKey || e.metaKey) && e.key=="s"){
            e.preventDefault()
            if(userData.isAdmin){
                save()
            }
        }
    }

    const save = ()=>{
        const newUserData = {...userData}
        newUserData.notes = notes
        fetch("/changenotes",{
            method:"POST",
            headers:{"content-type": "application/json"},
            body:JSON.stringify({
                notes
            })
        })
    }

    const userData = useSelector(state=>state.userData.userData)
    const [notes, setNotes] = useState(userData.notes ? userData.notes : "")
    const isDarkMode = useSelector(state=>state.isDarkMode)

    return(
        <div className={`notes ${isDarkMode ? "dark":"light"}`}>
            <h1>Notes</h1>
            <textarea onKeyDown={ctrls} placeholder="Aucune note" onChange={(e)=>{setNotes(e.target.value)}} value={notes}></textarea>
            {userData.isAdmin ? <button onClick={save}>Sauvegarder</button> : ""}
        </div>
    )
}