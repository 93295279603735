import { useEffect } from 'react';
import { useSelector, React, useDispatch } from 'react-redux';
import './App.scss';
import LoginPage from './components/loginPage';
import Main from './components/main';
import { changeDarkMode, changeLoggedIn, changeUserData } from './utils/rdxFunction';

function App() {

  const isDarkMode = useSelector(state=>state.isDarkMode)
  const isLoggedIn = useSelector(state=>state.isLoggedIn)

  const dispatch = useDispatch()

  useEffect(()=>{
    fetch("/amiloggedin")
    .then(data=>data.text())
    .then(data=>{
      if(data.includes("{")){
        dispatch(changeUserData(JSON.parse(data)))
        dispatch(changeDarkMode(JSON.parse(data).darkMode))
        dispatch(changeLoggedIn(true))
      }
    })
  },[])

  return (
    <div className={`App ${isDarkMode ? "dark":"light"}`} > 
      {isLoggedIn ? <Main/> : <LoginPage/>}
    </div>
  );
}

export default App;
