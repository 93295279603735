import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

export default function Settings({Windows, setModalWindow, logout}){

    const userData = useSelector(state=>state.userData.userData)
    const isDarkMode = useSelector(state=>state.isDarkMode)

    const downloadRef = useRef(null)
    const [downloadURL, setDownloadURL] = useState("")

    const deleteAccount = ()=>{
        if(confirm("vos données seront supprimées sans possibilité de récupération. Supprimer le compte ?")){
            fetch("/deleteAccount")
            .then(data=>data.text())
            .then(data=>{
                alert(data)
                if(data.includes("supprimé")){
                    logout()
                }
            })
        }
    }

    const generateXML = async ()=>{
        const xmlData = await(await fetch("/generate-xml-file")).text()
        if(downloadRef.current){
            const fileData = new Blob([xmlData], {type:"text/plain"})
            const url = URL.createObjectURL(fileData)
            setDownloadURL(url)
        }
    }

    useEffect(()=>{
        if(downloadURL !== ""){
            downloadRef.current.click()
            URL.revokeObjectURL(downloadURL)
            setDownloadURL("")
        }
    }, [downloadURL])



    return(
        <div className={`settings ${isDarkMode ? "dark":"light"}`}>
            <a download="translations.txt" href={downloadURL} style={{display:"none"}} ref={downloadRef}></a>
            <h1>Mes informations</h1>
            <div className="userData">
                <div className="userDataItem">
                    <img src={require("../images/user.png")} alt="Nom"/>
                    <h3>Nom</h3>
                    <p>{userData.name}</p>
                </div>
                <div className="userDataItem">
                    <img src={require("../images/description.png")} alt="Présentation"/>
                    <h3>Présentation</h3>
                    <p>{userData.description}</p>
                </div>
                <div className="userDataItem">
                    <img src={require("../images/email.png")} alt="E-mail"/>
                    <h3>Email</h3>
                    <p>{userData.email}</p>
                </div>
            </div>
            <h1>Panel administrateur</h1>
            <div className="userData">
                <div className="userDataItem" style={{opacity:userData.isAdmin ? 1:0.35}} onClick={()=>{setModalWindow(Windows.ADDUSER)}}>
                    <img src={require("../images/user.png")} alt="Ajouter utilisateur"/>
                    <h3>Ajouter utilisateur</h3>
                    <p></p>
                </div>
                <div className="userDataItem" onClick={()=>{setModalWindow(Windows.MODIFY)}}>
                    <img src={require("../images/logo.png")} alt="Modifier"/>
                    <h3>Modifier mes informations</h3>
                    <p></p>
                </div>
                <div className="userDataItem" onClick={userData.isAdmin ? generateXML : deleteAccount} >
                    <img src={require(userData.isAdmin ? "../images/logo.png" : "../images/delete.png")} alt={userData.isAdmin ? "Générer XML" : "Supprimer"} />
                    <h3>{userData.isAdmin ? "Générer XML":"Supprimer mon compte"}</h3>
                    <p></p>
                </div>
            </div>
        </div>
    )
}