import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alerts from "./notes";
import Editor from "./editor";
import { changeUserData, changeLoggedIn, changeDarkMode } from "../utils/rdxFunction";
import Settings from "./settings";
import Notes from "./notes";
import FirstConnection from "./firstConnection";
import AddUser from "./addUser";
import AllUsers from "./allUsers";

const Windows = {
    NOTES:"NOTES",
    SETTINGS:"SETTINGS",
    ALERTS:"ALERTS", 
    FIRSTCONNECTION:"FIRSTCONNECTION",
    ADDUSER:"ADDUSER",
    MODIFY:"MODIFY",
    ALLUSERS:"ALLUSERS"
}

export default function Main(){

    const [modalVisible, setModalVisible] = useState(false)
    const [modalWindow, setModalWindow] = useState(Windows.SETTINGS)

    const dispatch = useDispatch()
    const userData = useSelector(state=>state.userData.userData)
    const timer = useRef(null)

    const isDarkMode = useSelector(state=>state.isDarkMode)

    const changeDarkModes = ()=>{
        fetch("/changedarkmode",{
            method:"POST",
            headers:{"content-type":"application/json"},
            body:JSON.stringify({
                isDarkMode:!isDarkMode
            })
        })
        dispatch(changeDarkMode(!isDarkMode))
    }

    const changeSize = (size)=>{
        const newUserData = {...userData}
        newUserData.textSize += size
        dispatch(changeUserData(newUserData))
        clearTimeout(timer.current)
        timer.current = window.setTimeout(()=>{
            fetch("/changetextsize", {
                method:"POST",
                headers:{"content-type":"application/json"},
                body:JSON.stringify({
                    textSize:newUserData.textSize
                })
            })
        }, 2000)
    }

    const logout = ()=>{
        fetch("/logout")
        .then(data=>data.text())
        .then(data=>{
            if(data.includes("logout")){
                dispatch(changeLoggedIn(false))
            }
        })
    }

    useEffect(()=>{
        if(userData.firstConnection){
            setModalWindow(Windows.FIRSTCONNECTION)
            setModalVisible(true)
        }

    }, [])

    let windowModal
    switch(modalWindow){
        case Windows.NOTES:
            windowModal = <Notes/>
            break;
        case Windows.ALERTS:
            windowModal = <Alerts/>
            break;
        case Windows.FIRSTCONNECTION:
            windowModal = <FirstConnection setModalVisible={setModalVisible} setModalWindow={setModalWindow} Windows={Windows}/>
            break;
        case Windows.ADDUSER:
            windowModal = <AddUser Windows={Windows} setModalWindow={setModalWindow} type={Windows.ADDUSER} />
            break;
        case Windows.MODIFY:
            windowModal = <AddUser Windows={Windows} setModalWindow={setModalWindow} type={Windows.MODIFY} logout={logout} />
            break;
        case Windows.ALLUSERS:
            windowModal = <AllUsers setModalVisible={setModalVisible} />
            break
        case Windows.SETTINGS:
        default:
            windowModal = <Settings Windows={Windows} setModalWindow={setModalWindow} logout={logout} />
            break;
    }
    


    return(
        <div className={`main ${isDarkMode ? "dark":"light"}`}>
            <div className={`modal ${isDarkMode ? "dark":"light"}`} style={{display:modalVisible?"block":"none"}} onClick={()=>{setModalVisible(false)}}>
                <div className={`modalContainer ${isDarkMode ? "dark":"light"}`} onClick={(e)=>{e.stopPropagation()}}>
                    <button className="closeModal" onClick={()=>{setModalVisible(false)}}>x</button>
                    {windowModal}
                </div>
            </div>
            <div className={`mainMenu ${isDarkMode ? "dark":"light"}`}>
                <div className={`loginPageLogo ${isDarkMode ? "dark":"light"}`}>
                    <h1>CAST</h1>
                    <img src={require("../images/logo.png")} alt=""/>
                </div>
                <div className={`mainMenuControls ${isDarkMode ? "dark":"light"}`}>
                    <button onClick={()=>{changeSize(-1)}}>A-</button>
                    <button onClick={()=>{changeSize(1)}}>A+</button>
                    <button className="notesButton" onClick={changeDarkModes}>
                        <img src={require("../images/mode-switcher.png")} alt="Changer de mode"/>
                    </button>
                    <button className="notesButton" onClick={()=>{setModalWindow(Windows.NOTES);setModalVisible(true)}}><img src={require("../images/notes.png")} alt="Notes"/></button>
                    {
                        userData.allUsers.length ?
                        <button className="settingsButton" onClick={()=>{setModalWindow(Windows.ALLUSERS);setModalVisible(true)}}><img src={require("../images/users.png")} alt="Utilisateurs"/></button> : ""

                    }
                    <button className="settingsButton" onClick={()=>{setModalWindow(Windows.SETTINGS);setModalVisible(true)}}><img src={require("../images/settings.png")} alt="Paramètres"/></button>
                    <button className="logoutButton" onClick={logout}>Déconnexion <img src={require("../images/logout.png")} alt="->"/></button>
                </div>
            </div>
            <Editor/>
            
        </div>
    )
}